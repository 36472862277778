import _ from "lodash";
import crmService from "../../services/request/crm/crmService";
import novaPochtaService from "../../services/request/novaPochta/novaPochtaService";
import user from "../../mixins/user";
import EventBus from "../../events/EventBus";
import supplyService from "../../services/request/supply/supplyService";
import depotService from "../../services/request/depot/depotService";
import notifications from "../../mixins/notifications";
import addressService from "../../services/request/address/addressService";

export default {
  mixins: [user, notifications],
  methods: {
    async assignUpdate() {
      try {
        this.loading = true;
        if (this.settingDialog === "crm") {
          await crmService.startAssign(this.inputCrmItem.uuid);
          this.fetchData();
        }
        this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    nextPageStreets() {
      this.paginationStreets.page += 1;
      this.getStreets(true, false);
    },
    getStreets: _.debounce(async function (nextPage = false, change = false) {
      try {
        if (this.radioServiceType !== "DoorsWarehouse" || !this.inputAddress.city_address.Ref) return;
        // this.cityAddressLoading = true
        if (change) {
          this.inputAddress.city_flat = "";
          this.inputAddress.city_street = {};
          this.inputAddress.city_house = "";
          this.inputAddress.city_flat = "";

          this.inputDelivery.city = "";
          this.inputDelivery.name_city = "";
          this.inputDelivery.warehouse = {};
          this.inputDelivery.warehouse_id = "";
          this.inputDelivery.name_warehouse = "";

          this.paginationStreets.page = 1;
          this.streetsList = [];
        }

        const data = {
          Page: this.paginationStreets.page,
          FindByString: this.searchAddressStreetQuery,
          SettlementRef: this.inputAddress.city_address.Ref,
          Limit: this.paginationStreets.perPage
        }
        if (this.settingDialog === "crm" && this.inputCrmItem.uuid) {
          data.uuid_order = this.inputCrmItem.uuid
        }
        const res = await novaPochtaService.getAllStreets(data);
        if (nextPage) {
          this.streetsList.push(...res);
          // this.$forceUpdate()
        } else {
          this.streetsList = res;
          // this.$forceUpdate()
        }
        // this.cityAddressLoading = false
        this.loadMoreStreetsDisabled = res.length < this.pagination.perPage;
      } catch (e) {
        console.log(e)
      }
    }, 400),

    nextPageSettlements() {
      this.pagination.page += 1;
      this.getSettlements(true);
    },
    getSettlements: _.debounce(async function (nextPage = false) {
      try {
        if (this.radioServiceType !== "DoorsWarehouse") return;
        this.cityAddressLoading = true;
        const data = {
          Page: this.pagination.page,
          FindByString: this.searchAddressCityQuery
        }
        if (this.settingDialog === "crm" && this.inputCrmItem.uuid) {
          data.uuid_order = this.inputCrmItem.uuid
        }
        const res = await novaPochtaService.getAllSettlements(data);

        //
        if (nextPage) {
          this.settlementsList.push(...res);
          // this.$forceUpdate()
        } else {
          this.settlementsList = res;
          // this.$forceUpdate()
        }
        // this.inputAddress.city_address.AreaDescription = res[0]?.AreaDescription
        this.cityAddressLoading = false;
        this.loadMoreCitiesDisabled = res.length > this.pagination.perPage;
      } catch (e) {
        console.log(e)
      }
    }, 400),
    async requestBlocked(message) {
      try {
        let response = {};
        if (this.inputCrmItem.is_blocked) {
          response = await supplyService.unblockSupplyItem(this.inputCrmItem.uuid);
        } else {
          response = await supplyService.blockSupplyItem(this.inputCrmItem.uuid);

          this.tabStep = 2;
          try {
            const formData = new FormData();
            formData.append("uuid_object", this.inputCrmItem.uuid);
            formData.append("message", message);
            await supplyService.chatMessage(formData);
          } catch (e) {
            console.log(e)
          }
        }

        this.inputCrmItem.is_blocked = !this.inputCrmItem.is_blocked;
        this.inputCrmItem.is_blocked ? this.inputCrmItem.is_blocked = '1' : this.inputCrmItem.is_blocked = '0'
        EventBus.$emit("supply-out-modified",this.inputCrmItem);
      } catch (e) {
        console.error(e);
      }
    },
    getWarehouseNumber(warehouse) {

      if (warehouse.CategoryOfWarehouse === "Store") {
        const warehouseStreet = this.inputDelivery?.name_warehouse.split(" ");
        return warehouseStreet.splice(-3).join("");
      }
      const warehouseNumber = this.inputDelivery?.name_warehouse
        .split(" ")
        .find(e => e.startsWith("№"));
      if (warehouseNumber) {
        return warehouseNumber.replace("№", "");
      } else {
        return this.inputDelivery?.name_warehouse;
      }
    },
    getServiceType() {
      const defaultType = "WarehouseWarehouse";
      if (this.radioServiceType == "DoorsWarehouse") return this.radioServiceType;


      if (!this.inputDelivery.warehouse?.CategoryOfWarehouse) return defaultType;

      const SERVICE_TYPES = [
        {
          category: "Branch",
          type: "WarehouseWarehouse"
        },
        {
          category: "Postomat",
          type: "WarehouseWarehouse"
        },
        {
          category: "Store",
          type: "DoorsWarehouse"
        }
      ];

      const findedType = SERVICE_TYPES.find(
        e => e.category === this.inputDelivery.warehouse?.CategoryOfWarehouse
      );

      if (findedType) {
        return findedType.type;
      }
      return defaultType;
    },
    async createNovaPochtaTTN() {

      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {

          this.loadingCreateTTN = true;
          const formData = {
            apiKey: this.getUserProperty('np_apikey'),
            modelName: "InternetDocument",
            calledMethod: "save",
            methodProperties: {
              PayerType: this.counterparty.PayerType,
              PaymentMethod: this.counterparty.PaymentMethod,
              DateTime: new Date().toLocaleDateString("uk-UA"),
              CargoType: "Cargo",
              VolumeGeneral: "0.1",
              Weight: "1",
              ServiceType: this.getServiceType(),
              SeatsAmount: "1",
              Description: this.inputCrmItem?.description
                ? this.inputCrmItem?.description
                : "-----",
              Cost: Number(this.inputCrmItem.cost),
              CitySender: this.counterparty.uuid_ref_city,
              Sender: this.counterparty.uuid_ref,
              SenderAddress: this.counterparty.uuid_ref_warehouse,
              ContactSender: this.counterparty.uuid_ref_sender,
              SendersPhone: this.counterpartyPhone,
              RecipientsPhone: this.inputDelivery.phone,
              NewAddress: "1",
              RecipientCityName:
                this.inputDelivery.name_city || this.inputAddress.city_address?.Description,
              RecipientArea:
                this.radioServiceType === "WarehouseWarehouse"
                  ? ""
                  : this.inputAddress.city_address?.AreaDescription,
              RecipientAreaRegions: "",
              RecipientAddressName:
                this.radioServiceType === "WarehouseWarehouse"
                  ? this.getWarehouseNumber(this.inputDelivery.warehouse)
                  : this.inputAddress.city_street?.Description,
              RecipientFlat: this.inputAddress.city_flat,
              RecipientHouse: this.inputAddress.city_house,
              RecipientName: `${this.inputDelivery.surname} ${this.inputDelivery.name} ${this.inputDelivery.middlename}`,
              RecipientType: "PrivatePerson",
              OptionsSeat: [
                {
                  volumetricVolume: "1",
                  volumetricWidth: "1",
                  volumetricLength: "1",
                  volumetricHeight: "1",
                  weight: "1"
                }
              ]
            }
          };
          if (
            this.inputCrmItem.pay_type === "PAYMENT_CONTROL" &&
            this.counterparty.OwnershipFormDescription === "ФОП"
          ) {
            formData.methodProperties.AfterpaymentOnGoodsCost = this.inputCrmItem.cash_on_delivery;
          } else if (this.inputCrmItem.pay_type === "CASH_ON_DELIVERY") {
            // formData.methodProperties.BackwardDeliveryCargoTypes = "Money"
            formData.methodProperties.BackwardDeliveryData = [
              {
                PayerType: "Sender",
                CargoType: "Money",
                RedeliveryString: this.inputCrmItem.cash_on_delivery
              }
            ];
          }
          const response = await novaPochtaService.postCreateTTN(formData);
          if (response.data.success) {
            this.inputCrmItem.ttn = response.data.data[0].IntDocNumber;
            this.onTTN();
            this.onSearchByTTN();
            this.setSuccessNotification(this.$t("supply.successfully_created"));
          } else {
            response.data.errors.forEach(item => {
              this.setErrorNotification(this.$t(`orderDialog.${item.replaceAll(/[., ]/g, "_")}`));
            });
          }
          this.loadingCreateTTN = false;
        } catch (e) {
          console.error(e);
          this.loadingCreateTTN = false;
        }
      }
    },
    async getPhoneNovaPochta() {
      try {
        const formData = {
          apiKey: this.getUserProperty('np_apikey'),
          modelName: "Counterparty",
          calledMethod: "getCounterpartyContactPersons",
          methodProperties: {
            Ref: this.counterparty.uuid_ref,
            Page: "1"
          }
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // // orderDialog('getPhoneNovaPochta', response);
        const data = response.data.data.filter(
          item => item.Ref === this.counterparty.uuid_ref_sender
        );
        this.counterpartyPhone = data[0].Phones;
      } catch (e) {
        console.warn(e);
      }
    },
    async onSearchByTTN(checkUpdate) {
      if (!this.inputCrmItem.ttn || this.inputCrmItem.ttn === 0 || !this.inputCrmItem.ttn.length) {
        this.setErrorNotification(this.$t("form.errors.EnterTTN"));
      } else {
        try {
          this.ttnCodeSvg = this.inputCrmItem.ttn;
          const params = {
            ttn: this.inputCrmItem.ttn
          };
          if (this.inputSender.phone) {
            params.phone = this.inputSender.phone.replace(/[^+\d]+/g, "");
          }
          if (this.inputDelivery.phone) {
            params.phone = this.inputDelivery.phone.replace(/[^+\d]+/g, "");
          }

          const documents = await novaPochtaService.searchByTTN(params);
          if (documents.length) {
            const document = documents[0];
            //
            if (Number(document.StatusCode) === 3) {
              this.setErrorNotification(this.$t("form.errors.NonExistentTtn"));

              if (checkUpdate) {
                this.changedStatus = true;
              }
              this.ttnCodeStatus = false;
            } else {
              this.ttnCodeStatus = true;

              this.radioServiceType = document?.ServiceType;
              if (this.radioServiceType == "WarehouseWarehouse") {
                this.inputDelivery.city = document?.RefCityRecipient;
                // this.movement.check = true
                // this.movement.address = this.inputDelivery.city
              } else {
                const movement = await novaPochtaService.getMovement(this.inputCrmItem.ttn);
                this.movement.check = true;
                this.movement.address =
                  movement.status.addressDescription || document.RecipientAddress;

                this.searchAddressCityQuery = document?.CityRecipient;
                // this.inputDelivery.city = document?.RefSettlementRecipient;
                this.inputAddress.city_address.Ref = document?.RefSettlementRecipient;
              }
              if (document.SenderFullNameEW) {
                // eslint-disable-next-line prefer-destructuring
                this.inputSender.surname = document.SenderFullNameEW.split(" ")[0];
                // eslint-disable-next-line prefer-destructuring
                this.inputSender.name = document.SenderFullNameEW.split(" ")[1];
                // eslint-disable-next-line prefer-destructuring
                this.inputSender.middlename = document.SenderFullNameEW.split(" ")[2];
              }
              if (document.RecipientFullName.length) {
                // eslint-disable-next-line prefer-destructuring
                this.inputDelivery.surname = document.RecipientFullName.split(" ")[0];
                // eslint-disable-next-line prefer-destructuring
                this.inputDelivery.name = document.RecipientFullName.split(" ")[1];
                // eslint-disable-next-line prefer-destructuring
                this.inputDelivery.middlename = document.RecipientFullName.split(" ")[2];
              }
              if (document.PhoneSender) {
                this.inputSender.phone = document.PhoneSender.replace("38", "");
              }
              // if (this.inputDelivery.city && this.radioServiceType == "WarehouseWarehouse") {
              //   await this.onSelectCity(this.inputDelivery.city, "delivery");
              // }
              // if (document.AnnouncedPrice) {
              //   this.inputCrmItem.cost = document.AnnouncedPrice;
              // }
              if (document.FactualWeight) {
                this.inputCrmItem.weight = document.FactualWeight;
              }
              this.inputCrmItem.cash_on_delivery = document.AfterpaymentOnGoodsCost;
              if (document.DocumentCost) {
                this.inputCrmItem.delivery_price = document.DocumentCost;
              }
              if (document.AdditionalInformationEW) {
                this.inputCrmItem.description = document.AdditionalInformationEW;
              }
              // const city = this.cities.find((cityItem) => cityItem.Ref === this.inputDelivery.city);
              if (document.WarehouseRecipientInternetAddressRef) {
                this.inputDelivery.warehouse.Ref = document.WarehouseRecipientInternetAddressRef;
                this.inputDelivery.name_warehouse = document.WarehouseRecipient;
                this.inputDelivery.warehouse.CategoryOfWarehouse = document.CategoryOfWarehouse;
              }
              // this.inputCrmItem.pay_type = null
              // if(document.PaymentMethod){
              //   switch(document.PaymentMethod){
              //     case "Cash": this.inputCrmItem.pay_type  = 'CASH_ON_DELIVERY'
              //     break;
              //     default: this.inputCrmItem.pay_type = null
              //   }
              // }
              this.$forceUpdate();
              // this.inputCrmItem.cost = document?.DocumentCost;
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    async onloadTTN() {
      try {
        if (!this.getUser.np_apikey) {
          this.setErrorNotification(this.$t("form.errors.EnterApiKey"));
        }
        if (!this.inputCrmItem.ttn || this.inputCrmItem.ttn === 0 || !this.inputCrmItem.ttn.length) {
          this.setErrorNotification(this.$t("form.errors.EnterTTN"));
          return
        }
        let response = ''
        if (this.typeDialog === 'edit') {
          if (!this.isRewrite) {
            this.showTTNRewrite = true
            return
          }
          const formData = new FormData();
          formData.append("ttn", this.inputCrmItem.ttn);
          response = await supplyService.loadTTN(this.inputCrmItem.uuid, formData);
          this.isRewrite = false
          await this.dataInsertion(response);

        } else {
          response = await supplyService.infoTTN(this.inputCrmItem.ttn);
          if (response) {
            this.ttnCodeStatus = true;
            await this.insertTTNData(response)
          } else {
            this.loading = false;

            this.ttnCodeStatus = false;
            return
          }
        }
        this.ttnCodeSvg = this.inputCrmItem.ttn;
        this.ttnCodeStatus = true;
        this.disableRequiredFields = true;

        // await this.dataInsertion(response);
        this.loading = false;
        //  this.onTTN();

      } catch (e) {
        console.log(e)
        this.loading = false;
      }
    },
    insertTTNData(response) {
      this.radioServiceType = response.delivery_address_details.service_type;
      if (this.radioServiceType == "WarehouseWarehouse") {
        this.inputDelivery.city = response.delivery_address_details.uuid_ref_city
        this.inputDelivery.name_city = response.delivery_address_details.name_city
        if (response.delivery_address_details.uuid_ref_warehouse) {
          this.inputDelivery.warehouse.Ref = response.delivery_address_details.uuid_ref_warehouse;
          this.inputDelivery.name_warehouse = response.delivery_address_details.name_warehouse;
          this.inputDelivery.warehouse.CategoryOfWarehouse = response.delivery_address_details.category_of_warehouse;
        }
        this.activeWarehouse = [
          {
            Ref: response.delivery_address_details.uuid_ref_warehouse,
            Description: response.delivery_address_details.name_warehouse
          }
        ];
        this.activeCities = [
          {
            Ref: response.delivery_address_details.uuid_ref_city,
            Description: response.delivery_address_details.name_city
          }
        ];
      }
      else {
        // this.searchAddressCityQuery = response.delivery_address_details?.name_city;
        this.inputAddress = {
          city_address: {
            Ref: response.delivery_address_details?.uuid_ref_city,
            Description: response.delivery_address_details?.name_city
          },
          city_street: {
            Ref: response.delivery_address_details?.uuid_ref_street,
            Description: response.delivery_address_details?.name_street
          },
          city_house: response.delivery_address_details?.number_house,
          city_flat: response.delivery_address_details?.number_flat
        };
        this.searchAddressCityQuery = response.delivery_address_details?.name_city;
        this.searchAddressStreetQuery = response.delivery_address_details?.name_street;
        this.settlementsList = [
          {
            Ref: response.delivery_address_details?.uuid_ref_city,
            Description: response.delivery_address_details?.name_city
          }
        ];
        this.streetsList = [
          {
            Ref: response.delivery_address_details?.uuid_ref_street,
            Description: response.delivery_address_details?.name_street
          }
        ];
      }
      // else {
      //   const movement = await novaPochtaService.getMovement(this.inputCrmItem.ttn);
      //   this.movement.check = true;
      //   this.movement.address = movement.status.addressDescription || document.RecipientAddress;
      //   this.searchAddressCityQuery = document?.CityRecipient;
      //   this.inputAddress.city_address.Ref = document?.RefSettlementRecipient;
      // }
      this.inputSender.surname = response.sender_address_details.surname;
      this.inputSender.name = response.sender_address_details.name;
      this.inputSender.middlename = response.sender_address_details.middlename;
      if (response.sender_address_details.phone) {
        this.inputSender.phone = response.sender_address_details.phone.replace("38", "")
      }

      this.inputDelivery.surname = response.delivery_address_details.surname;
      this.inputDelivery.name = response.delivery_address_details.name;
      this.inputDelivery.middlename = response.delivery_address_details.middlename;
      if (response.delivery_address_details.phone) {
        this.inputDelivery.phone = response.delivery_address_details.phone.replace("38", "")
      }
      this.inputCrmItem.description = response.description
    },
    async generateTTN(data) {
      try {
        await this.onUpdate("save");
        this.generationTTNconfirm = false
        const formData = new FormData();
        formData.append("uuid_counterparty", this.counterparty.uuid);
        formData.append("Description", data.description);
        formData.append("AdditionalInformation", data.additionalInfo);

        const res = await supplyService.generateTTN(this.inputCrmItem.uuid, formData)
        this.inputCrmItem.ttn = res.data[0].IntDocNumber;
        this.ttnCodeSvg = this.inputCrmItem.ttn;
        this.ttnCodeStatus = true;

        // this.onSearchByTTN();

        this.onTTN();
      } catch (e) {
        console.log(e)
        this.setErrorNotification(e?.error?.description);

      }
    },
    async onUpdateState() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("uuid", this.inputCrmItem.uuid);
        formData.append("id_state", this.inputCrmItem.id_state);

        const response = await supplyService.updateSupplyItem(formData);
        this.dataInsertion(response.data?.object);

        EventBus.$emit('supply-out-modified',this.inputCrmItem);
        // this.$root.$emit("updated-notify");
        this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
        this.loading = false;
        this.visibility = false;
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e?.error?.description);
      }
    },
    async dataInsertion(item) {
      this.loading = true;
      this.isInsertData = true
      this.activeProduct = true;
      if (this.typeDialog === "edit" && this.listCounterparty.length) {
        this.statusCreateTTN = Number(item?.is_counterparty_used);
      }
      this.item.number = item.number;
      this.item.uuid = item.uuid;
      this.item.state_details = item.state_details;
      this.item.id_state = item.id_state;
      this.uuid_user_state = item.uuid_user_state;
      this.item.uuid_user_assigned_to = item.uuid_user_assigned_to;
      this.item.user_assigned_to_details = { ...item.user_assigned_to_details };
      this.uuid_user_assigned_to = item.uuid_user_assigned_to;
      this.ttnCodeSvg = item.ttn > 0 ? item.ttn : "";
      this.ttnCodeStatus = item.ttn > 0 ? true : false;
      this.inputCrmItem.description = item.cell_details?.description;
      this.inputCrmItem.pay_type = item.pay_type;
      this.inputCrmItem.cash_on_delivery = item.cash_on_delivery;
      this.inputCrmItem.uuid = item.uuid || " ";
      this.inputCrmItem = {
        ...this.inputCrmItem,
        ...item
      };
      this.inputCrmItem.is_blocked = !(item.is_blocked === null || Number(item.is_blocked) === 0);

      this.inputSender = {
        ...this.inputSender,
        name: item.sender_address_details?.name || "",
        surname: item.sender_address_details?.surname || "",
        middlename: item.sender_address_details?.middlename || ""
      };


      if (item.sender_address_details?.phone) {
        this.inputSender.phone = item.sender_address_details?.phone.replace("+38", "") || "";
      }
      this.radioServiceType = item.delivery_address_details?.service_type;

      this.inputDelivery = {
        ...this.inputDelivery,
        name: item.delivery_address_details?.name || "",
        surname: item.delivery_address_details?.surname || "",
        middlename: item.delivery_address_details?.middlename || "",
        city: item.delivery_address_details?.uuid_ref_city || "",
        warehouse: {
          ...this.inputDelivery.warehouse,
          Ref: item.delivery_address_details?.uuid_ref_warehouse,
          CategoryOfWarehouse: item.delivery_address_details?.category_of_warehouse
        },
        name_warehouse: item.delivery_address_details?.name_warehouse || "",
        name_city: item.delivery_address_details?.name_city
      };



      if (this.radioServiceType == "DoorsWarehouse") {
        this.inputDelivery.city = "";
        this.inputDelivery.name_city = "";
        this.inputDelivery.name_warehouse = "";
        this.inputDelivery.warehouse = {};

        this.inputAddress = {
          city_address: {
            Ref: item.delivery_address_details?.uuid_ref_city,
            Description: item.delivery_address_details?.name_city
          },
          city_street: {
            Ref: item.delivery_address_details?.uuid_ref_street,
            Description: item.delivery_address_details?.name_street
          },
          city_house: item.delivery_address_details?.number_house,
          city_flat: item.delivery_address_details?.number_flat
        };
        this.searchAddressCityQuery = item.delivery_address_details?.name_city;
        this.searchAddressStreetQuery = item.delivery_address_details?.name_street;
        this.settlementsList = [
          {
            Ref: item.delivery_address_details?.uuid_ref_city,
            Description: item.delivery_address_details?.name_city
          }
        ];
        this.streetsList = [
          {
            Ref: item.delivery_address_details?.uuid_ref_street,
            Description: item.delivery_address_details?.name_street
          }
        ];
      } else {
        this.activeCities = [
          {
            Ref: item.delivery_address_details?.uuid_ref_city,
            Description: item.delivery_address_details?.name_city
          }
        ];
        this.activeWarehouse = [
          {
            Ref: this.inputDelivery.warehouse.Ref,
            Description: item.delivery_address_details?.name_warehouse
          }
        ];
      }
      this.isProductPriceCashOnDelivery = item.is_auto_cash_on_delivery == '1'
      this.isProductPriceCashOnDeliveryCopy = this.isProductPriceCashOnDelivery
      this.isProductPriceCost = item.is_auto_cost == '1'
      this.isProductPriceCostCopy = this.isProductPriceCost

      if (item.delivery_address_details?.phone) {
        this.inputDelivery.phone = item.delivery_address_details?.phone.replace("+38", "") || "";
      }

      this.updateNameAddress();
      // orderDialog('item.ttn', item);
      // orderDialog('item.ttn', Number(item.ttn));
      if (item?.ttn?.length > 0) {
        //  this.onSearchByTTN(true);
        this.disableRequiredFields = true
        this.changedStatus = false;
      } else {
        this.changedStatus = true;
      }
      if (this.settingDialog === "supply") {
        this.states = await depotService.getAllStates({
          type: "DELIVERY_OUT"
        });
        // orderDialog('item.id_state', item);
        const nextStates = await supplyService.getNextStatusId(item.id_state || '400');
        const nextStateIds = nextStates.map(state => state.id_state_next);
        if (nextStateIds.length) {
          this.filteredStates = this.states.map(
            // eslint-disable-next-line array-callback-return,consistent-return
            state => {
              if (nextStateIds.includes(state.id.toString())) {
                return state;
              }
              if (item.id_state === state.id.toString()) {
                return state;
              }
            }
          );
          this.filteredStates = this.filteredStates.filter(item => item);
        }
        // orderDialog('filteredStates', this.filteredStates);
      }
      this.copySender = {
        ...this.inputSender
      };

      this.copyAddress = {
        ...this.inputAddress
      };
      this.copyDelivery = {
        ...this.inputDelivery
      };
      this.copyCrmItem = {
        ...this.inputCrmItem
      };
      this.copyStatusCreateTTN = Boolean(this.statusCreateTTN);
      this.copyRadioServiceType = this.radioServiceType;
      this.copy_uuid_user_assigned_to = this.uuid_user_assigned_to;

      this.changed = false;
      this.loading = false;

      // this.getMetrics();
      return true;
    },
    async onInputDeliveryValue(property) {
      try {
        if (property === "phone") {
          if (this.inputDelivery.phone) {
            this.delivery.items = await addressService.getAddressesList({
              phone: this.inputDelivery.phone,
              uselike: true
            });
            if (this.delivery.items) {
              this.delivery.phoneMenu = true;
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async onChangeDeliveryAddress(property, addressObject) {
      if (typeof addressObject === "object") {
        this.deliveryAddress = {
          ...addressObject
        };
        if (this.deliveryAddress.name) {
          this.inputDelivery.name = this.deliveryAddress.name;
        }
        if (this.deliveryAddress.surname) {
          this.inputDelivery.surname = this.deliveryAddress.surname;
        }
        if (this.deliveryAddress.middlename) {
          this.inputDelivery.middlename = this.deliveryAddress.middlename;
        }
        if (this.deliveryAddress.phone) {
          this.inputDelivery.phone = this.deliveryAddress.phone.replace("+38", "");
        }
        this.inputDelivery.city = this.deliveryAddress.uuid_ref_city;

        // await this.onSelectCity(this.inputDelivery.city, "delivery");
        this.inputDelivery.warehouse.Ref = this.deliveryAddress.uuid_ref_warehouse;
        this.inputDelivery.warehouse.CategoryOfWarehouse = this.deliveryAddress.category_of_warehouse;
        this.inputDelivery.name_warehouse = this.deliveryAddress.name_warehouse;
        this.inputDelivery.warehouse.Ref = this.deliveryAddress.uuid_ref_warehouse;
        this.inputDelivery.name_city = this.deliveryAddress.name_city

        if (this.inputDelivery.warehouse) {
          this.radioServiceType = "WarehouseWarehouse";
          this.searchAddressCityQuery = "";
          this.searchAddressStreetQuery = "";
          this.inputAddress.city_address = {};
          this.inputAddress.city_street = {};
          this.inputAddress.city_house = "";
          this.inputAddress.city_flat = "";
        }

        this.isInsertData = true
        // this.cityPagination.ref = this.deliveryAddress.uuid_ref_city
        this.activeCities = [
          {
            Ref: this.deliveryAddress.uuid_ref_city,
            Description: this.deliveryAddress.name_city
          }
        ];
        this.activeWarehouse = [
          {
            Ref: this.deliveryAddress.uuid_ref_warehouse,
            Description: this.deliveryAddress.name_warehouse
          }
        ];


        this.inputDelivery.email = this.deliveryAddress.email;
        if (this.deliveryAddress.uuid) {
          this.inputDelivery.uuid = this.deliveryAddress.uuid;
        }

      } else if (typeof addressObject === "string") {
        if (property === "phone") {
          this.inputDelivery.phone = addressObject;
        }
        this.inputDelivery.uuid = "";
      } else if (addressObject === null) {
        this.inputDelivery[property] = null;
      }
    },
    async onInputSenderValue(property) {
      try {
        if (property === "phone") {
          if (this.inputSender.phone) {
            this.sender.items = await addressService.getAddressesList({
              phone: this.sender.phone,
              uselike: true
            });
            if (this.sender.items) {
              this.sender.phoneMenu = true;
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async onChangeSenderAddress(property, addressObject) {
      if (typeof addressObject === "object") {
        this.senderAddress = {
          ...addressObject
        };
        if (this.senderAddress.name) {
          this.inputSender.name = this.senderAddress.name;
        }
        if (this.senderAddress.surname) {
          this.inputSender.surname = this.senderAddress.surname;
        }
        if (this.senderAddress.middlename) {
          this.inputSender.middlename = this.senderAddress.middlename;
        }
        if (this.senderAddress.phone) {
          this.inputSender.phone = this.senderAddress.phone.replace("+38", "");
        }
        this.inputSender.warehouse = this.senderAddress.uuid_ref_warehouse;
        this.inputSender.city = this.senderAddress.uuid_ref_city;

        // this.cityPagination.ref = this.senderAddress.uuid_ref_city;
        await this.onFocusCities(false);
        await this.fetchWarehouseByRef(this.inputDelivery.warehouse.Ref);
        this.activeWarehouse = this.warehouses;

        if (this.senderAddress.uuid) {
          this.inputSender.uuid = this.senderAddress.uuid;
        }
      } else if (typeof addressObject === "string") {
        if (property === "phone") {
          this.inputSender.phone = addressObject;
        }
        this.inputSender.uuid = "";
      } else if (addressObject === null) {
        this.inputSender[property] = null;
      }
    }
  }
};
