<template>
  <VDialog
    v-model="visibility"
    max-width="800px"
    :persistent="changed"
    @click:outside="showConfirmDialog"
    content-class="cross__dialog dialog-order"
    :scrollable="true"
    :transition="$dialogTransition"
  >
    <VCard>
      <div class="cross__inner">
        <VBtn
          fab
          small
          elevation="0"
          @click="showConfirmDialog"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle class="d-block">
        <VRow class="pa-0 ma-0  mb-1" align="center" justify="space-between">
            <VBtn
              color="success"
              text
              class="br-10 mx-2"
              @click="onReturned"
              v-if="supply.uuid_parent !== '00000000-0000-0000-0000-000000000000'"
            >
              <VIcon :left="!$vuetify.breakpoint.smAndDown">mdi-inbox-arrow-down</VIcon>
              <div v-if="!$vuetify.breakpoint.smAndDown">{{ $t("supply.returned_supply") }}</div>
            </VBtn>

          <VMenu
            :close-on-content-click="false"
            offset-y
            v-if="$vuetify.breakpoint.smAndDown"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <div>
                <VIcon v-bind="attrs" v-on="on">mdi-dots-vertical</VIcon>
              </div>
            </template>
            <VCard>
              <v-list :width="filteredStates.length ? '300' : '200'">
                <v-list-item
                  @click="showConfirmDialog"
                  v-if="$vuetify.breakpoint.smAndDown"
                  :style="$vuetify.breakpoint.smAndDown ? 'min-width:200px' : ''"
                  color="error"
                >
                  <VRow align="center">
                    <VCol cols="2">
                      <VIcon :dense="$vuetify.breakpoint.smAndDown">mdi-close</VIcon>
                    </VCol>
                    <VCol
                      cols="10"
                      class="text-left text-body-2"
                      :class="{ 'pl-0': filteredStates.length }"
                    >
                      {{ $t("btn.close") }}
                    </VCol>
                  </VRow>
                </v-list-item>
              </v-list>
            </VCard>
          </VMenu>
        </VRow>

        <VRow align="center" justify="space-between" no-gutters>
          <VCol cols="12" class="px-0 py-0" xl="auto" md="auto" sm="auto">
            <v-card-subtitle class="pt-0 pb-1 px-0 d-flex align-center">
              {{ "#" }} – {{ supply.number }}
              <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                v-on="on"
                icon
                color="primary"
                small
                class="btn-edit sm"
                @click="historyDialog = true"
                v-if="permissions.deliveries_history_chart"
              >
                <VIcon small dense>mdi-history</VIcon>
              </VBtn>
            </template>
            {{ $t("supply.shipping_history") }}
          </v-tooltip>
            </v-card-subtitle>

          </VCol>

          <VCol cols="12" xl="auto" md="auto" sm="auto" v-if="filteredStates.length">
            <VSelect
              :items="filteredStates"
              filled
              :item-text="getStatusText"
              item-value="id"
              dense
              single-line
              placeholder="Статус"
              hide-details
              ref="stateChange"
              v-model="supplyItem.id_state"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-content-start ">
                  <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
                  <div class="elispsis">
                    {{ item?.[`${currentLanguage.key}_name`] || item?.name || '' }}
                  </div>
                </div>
              </template>
            </VSelect>
          </VCol>
          <VCol v-else cols="12" xl="auto" md="auto" sm="auto">
            <v-card-subtitle class="pt-0 pb-1 px-0" v-if="!filteredStates.length">
              <div class="d-flex align-center justify-content-start ">
                <StatusCircle :status-name="supply.id_state" :type="'supply'" :class="'sm'" />
                <div class="elispsis">
                  {{ getStatusText(getStatusObject()) }}
                </div>
              </div>
            </v-card-subtitle>
          </VCol>
        </VRow>
      </VCardTitle>
      <VDivider class="mb-3 " />
      <VCardText class="py-3">
        <div class="text-center" v-if="loading">
          <VProgressCircular :size="50" color="primary" indeterminate></VProgressCircular>
        </div>
        <div v-else>
          <VRow class="align-center mb-3" :class="{ 'pt-4': permissions.client }">
            <VCol v-if="!permissions.client" cols="12" md="6">
              <v-autocomplete
                dense
                :label="$t('form.client')"
                outlined
                :items="clients"
                :item-value="'uuid'"
                :item-text="'name'"
                v-model="supplyItem.uuid_user"
                class="br-10"
                color="success"
                item-color="success"
                :error-messages="clientUuidErrors"
                :no-data-text="$t('table.noData')"
                hide-details="auto"
                :readonly="
                  (supply.id_state === '321' && permissions.client) ||
                  (supply.id_state === '360' && permissions.not_client) ||
                  (supply.id_state === '360' && permissions.client) ||
                  (supply.id_state === '330' && permissions.client) ||
                  (supply.id_state === '320' && permissions.not_client)
                "
              >
                <template slot="item" slot-scope="data">
                  <span>{{ `${data.item.name} ${data.item.surname}` }}</span>
                </template>
                <template slot="selection" slot-scope="data">
                  <VChip small>
                    {{ `${data.item.name} ${data.item.surname}` }}
                  </VChip>
                </template>
              </v-autocomplete>
            </VCol>
            <VCol
              v-if="!permissions.client"
              cols="12"
              md="6"
              :class="{ 'text-center mb-3': $vuetify.breakpoint.smAndDown }"
            >
              <span class="mx-6 font-weight-bold" style="color: black">{{ $t("supply.or") }}</span>
              <VBtn
                plain
                color="green"
                @click="$emit('add-customer')"
                :class="{ 'text-caption': $vuetify.breakpoint.xs }"
                :disabled="(supply.id_state === '320' && permissions.not_client)"
              >
                <VIcon left>mdi-account-plus</VIcon>
                {{ $t("supply.add_new") }}
              </VBtn>
            </VCol>
            <VCol cols="12">
              <VTextField
                label="ТТН"
                class="br-10"
                dense
                hide-details="auto"
                outlined
                color="green"
                v-model="supplyItem.ttn"
                maxLength="19"
                :readonly="
                  (supply.id_state === '321' && permissions.client) ||
                    (supply.id_state === '360' && permissions.not_client) ||
                    (supply.id_state === '360' && permissions.client) ||
                    (supply.id_state === '330' && permissions.client) ||
                    (supply.id_state === '320')
                "
                @keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
              />
            </VCol>
            <VCol cols="12">
              <VTextarea
                no-resize
                rows="2"
                class="br-10"
                outlined
                dense
                :label="$t('form.comment')"
                v-model="supplyItem.description"
                required
                counter
                maxlength="200"
                color="green"
                hide-details="auto"
                :readonly="
                  (supply.id_state === '321' && permissions.client) ||
                    (supply.id_state === '360' && permissions.not_client) ||
                    (supply.id_state === '360' && permissions.client) ||
                    (supply.id_state === '330' && permissions.client) ||
                    (supply.id_state === '320')
                "
              ></VTextarea>
            </VCol>
          </VRow>
        </div>
        <VRow no-gutters>
          <div v-if="!permissions.client" class="text-left " cols="12" md="12">
            <div class="supplyInTab__nav">
              <VBtn
                class="supplyInTab__nav-item mx-0"
                @click="tabStep = 0"
                :class="{ active: tabStep === 0 }"
              >
                <span v-if="!$vuetify.breakpoint.smAndDown">{{
                  $t("supply.confirmed_items")
                }}</span>
                <VIcon v-else>mdi-archive-check-outline</VIcon>
              </VBtn>
              <VBtn
                v-if="isReturned"
                class="supplyInTab__nav-item mx-0"
                @click="tabStep = 1"
                :class="{ active: tabStep === 1 }"
              >
                <span v-if="!$vuetify.breakpoint.smAndDown">{{ $t("supply.returned_items") }}</span>
                <VIcon v-else>mdi-inbox-arrow-down</VIcon>
              </VBtn>

              <VBtn
                class="supplyInTab__nav-item mx-0"
                @click="tabStep = 2"
                :class="{ active: tabStep === 2 }"
              >
                <span v-if="!$vuetify.breakpoint.smAndDown">{{ $t("supply.cell") }}</span>
                <VIcon v-else>mdi-archive-eye-outline</VIcon>
              </VBtn>
            </div>
          </div>
          <VRow justify="end" class="mb-0" no-gutters>
            <VCol cols="auto">
              <VBtn
                plain
                :disabled="isDisabledDetails"
                dense
                color="primary"
                :small="$vuetify.breakpoint.smAndDown"
                @click="onClickDetails"
              >
                <VIcon left dense>
                  mdi-printer
                </VIcon>
                {{ $t("btn.print") }}
              </VBtn>
            </VCol>
          </VRow>
        </VRow>

        <VDivider />
        <div class="supplyInTab">
          <VTabsItems class="supplyInTab__content" v-model="tabStep" :touchless="true">
            <VTabItem>
              <item-view-tree-view :items="productsInСonfirmed" :loadingItems="loadAcces" />
            </VTabItem>
            <VTabItem>
              <item-view-tree-view
                type="supply-returned-items"
                :items="returnedItems"
                :loadingItems="loadIn"
              />
            </VTabItem>
            <VTabItem>
              <item-view-tree-view :items="productsIn" :loadingItems="loadIn" />
            </VTabItem>
          </VTabsItems>
        </div>
        <VRow class="justify-center" no-gutters>
          <VCol
            cols="12"
            md="6"
            v-if="!['321', '322'].includes(supply.id_state) && supply.id_state === '320'"
          >
            <VBtn
              depressed
              @click="confirmSupply"
              color="green"
              block
              outlined
              large
              style="border-radius: 25px"
              class="text-transform-none text-white"
              :loading="confirmLoading"
            >
              {{ $t("btn.confirm") }}
            </VBtn>
          </VCol>
          <VCol cols="12" md="6" v-if="supply.id_state === '321'">
            <VBtn
              depressed
              block
              large
              color="transparent"
              style="border-radius: 25px"
              class="text-transform-none text-white"
            >
              <VIcon color="green" size="36" left>
                mdi-checkbox-marked-circle
              </VIcon>
              <span style="color: green">{{ $t("supply.confirmed") }}</span>
            </VBtn>
          </VCol>
        </VRow>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6" class="my-0 py-0">
            <VBtn
              @click="visibility = false"
              dark
              block
              class="br-10"
              :disabled="loading"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6" class="my-0 py-0">
            <VBtn
              :disabled="
                (supply.id_state === '321' && permissions.client) ||
                  (supply.id_state === '360' && permissions.not_client) ||
                  (supply.id_state === '360' && permissions.client) ||
                  (supply.id_state === '330' && permissions.client) ||
                  (supply.id_state === '320' && permissions.client) ||
                  !changed
              "
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              :loading="loading"
              @click="onUpdate"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.update") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <headerHistoryDialog
      :visible="historyDialog"
      @close="onCloseHistoryDialog"
      v-if="permissions.deliveries_history_chart && historyDialog"
      :uuid="supply.uuid"
    />
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
    <DialogWrapper
      v-if="showReturnedDialog"
      :visible="showReturnedDialog"
      @close="showReturnedDialog = false"
      :typeDialog="'edit'"
      settingDialog="supply"
    >
      <OrderDialog
        :uuidItem="supply.uuid_parent"
        :typeDialog="'edit'"
        settingDialog="supply"
      />
    </DialogWrapper>
  </VDialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import dialogMixin from "../../mixins/dialogMixin";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import supplyService from "../../services/request/supply/supplyService";
import EventBus from "../../events/EventBus";
import states from "../../constants/states";
import usersService from "../../services/request/users/usersService";
import user from "../../mixins/user";
import depotService from "../../services/request/depot/depotService";
import imgDialog from "@/components/products/imgDialog.vue";
import itemViewTreeView from "./dialogs/itemViewTreeView.vue";
import { mapGetters } from "vuex";
import StatusCircle from "../move-list/StatusCircle.vue";
import {validationForNumbers} from "@/mixins/helpers";
import headerHistoryDialog from "@/components/orderDialog/components/header/headerHistoryDialog.vue";

const defaultUUID = "00000000-0000-0000-0000-000000000000";
export default {
  name: "EditSupplyInDialog",
  mixins: [dialogMixin, language, notifications, validationMixin, user],
  validations: {
    supplyItem: {
      uuid_user: { required }
    }
  },
  data: () => ({
    imgpath: [],
    imgDialog: false,
    isImgLoaded: false,
    tabStep: 0,
    loading: false,
    clients: [],
    supplyItem: {
      uuid_user: "",
      ttn: "",
      description: ""
    },
    confirmLoading: false,
    filteredStates: [],
    states: [],
    productsIn: [],
    productsInСonfirmed: [],
    visibleConfirm: false,
    changed: false,
    inputProducts: {},
    moreDialogVisible: false,
    loadIn: true,
    loadAcces: true,
    showReturnedDialog: false,
    returnedItems: [],
    historyDialog: false
  }),
  watch: {
    supplyItem: {
      deep: true,
      handler(e) {
        // orderDialog(e);
        this.changed = !_.isEqual(this.supplyItem, this.inputProducts);
        // orderDialog(this.supplyItem);
        // orderDialog(this.inputProducts);
        // orderDialog(this.changed);
      }
    }
  },
  components: {
    ConfirmLeftDialog,
    StatusCircle,
    imgDialog,
    OrderDialog: () => import("@/components/orderDialog/OrderDialog.vue"),
    DialogWrapper: () => import("@/components/orderDialog/DialogWrapper.vue"),
    itemViewTreeView,
    headerHistoryDialog
  },
  props: {
    supply: {
      required: true
    }
  },
  async mounted() {
    // orderDialog("supply", this.supply);

    try {
      this.supplyItem = {
        ttn: this.supply.ttn,
        description: this.supply.cell_details.description,
        uuid_user: this.supply.uuid_user,
        id_state: this.supply.id_state
      };
      this.inputProducts = {
        ...this.supplyItem
      };
      // // orderDialog('this.supply', this.supply);
      // // orderDialog('supplyItem', this.supplyItem);
      if (!this.permissions.client) {
        this.clients = await usersService.getClientsList();
        // orderDialog("clients", this.clients);
      }
      // // orderDialog('this.clients', this.clients);
      this.states = await depotService.getAllStates({
        type: "DELIVERY_IN"
      });
      await this.fetchNextStates(this.supply.id_state);

      if (Number(this.supply.id_state) >= 321) {
        this.loadAcces = true;
        this.productsInСonfirmed = await depotService.getMyСonfirmedProducts(
          this.supply.uuid,
          true
        );
        if (this.permissions.client) {
          this.tabStep = 0;
        }
      } else {
        if (this.permissions.client) {
          this.tabStep = 2;
        }
      }
      if (this.permissions.client) {
        if (this.isReturned) {
          this.tabStep = 1;
        }
      }
      this.loadAcces = false;

      this.loadIn = true;

      this.productsIn = await depotService.getMyProducts(
        {
          uuid_cell: this.supply.uuid_cell
        },
        true
      );
      if (this.isReturned) {
        this.returnedItems = await supplyService.returnItemList({
          uuid: this.supply.uuid,
          details: true
        });

      }
      this.loadIn = false;
    } catch (e) {
      console.log(e)
    }
  },

  methods: {
    validationForNumbers,
    getStatusText(item) {
      if (!item) {
        return null;
      } else {
        if (
          item[this.currentLanguage.key + "_name"] !== undefined &&
          item[this.currentLanguage.key + "_name"] !== null
        ) {
          return item[this.currentLanguage.key + "_name"];
        } else if (item.name !== undefined && item.name !== null) {
          return item.name;
        } else {
          return "";
        }
      }
    },
    getStatusObject() {
      return this.states.find(state => state.id == this.supply.id_state);
    },
    onClickDetails() {
      switch (this.tabStep) {
        case 0:
          this.onClickMoreConfirmed();
          break;
        case 1:
          this.onClickMoreReturned();
          break;
        case 2:
          this.onClickMoreIn();
          break;
        default:
      }
    },
    async onClickMoreConfirmed() {
      // this.moreDialogVisible = true
      const route = this.$router.resolve({
        // path:`/supply/details?${this.supply.uuid}&${this.supply.number}`,
        name: "supply-details",
        params: {
          id: this.supply.uuid,
          type: "confirmed",
          lang: this.currentLanguage.key
        }
      });
      window.open(route.href, "_blank");
      //
    },
    async onClickMoreIn() {
      // this.moreDialogVisible = true
      const route = this.$router.resolve({
        // path:`/supply/details?${this.supply.uuid}&${this.supply.number}`,
        name: "supply-details",
        params: {
          id: this.supply.uuid,
          type: "in",
          lang: this.currentLanguage.key
        }
      });
      window.open(route.href, "_blank");
    },
    async onClickMoreReturned() {
      // this.moreDialogVisible = true
      const route = this.$router.resolve({
        name: "supply-details",
        params: {
          id: this.supply.uuid,
          type: "returned",
          lang: this.currentLanguage.key
        }
      });

      window.open(route.href, "_blank");
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    showImgDialog(product) {
      this.imgpath = product.product_details.path_img;
      this.imgDialog = true;
    },
    showConfirmDialog() {
      // orderDialog(this.changed);
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
      this.$emit("resetSearchField");
    },
    onCloseHistoryDialog(){
      this.historyDialog = false;
    },
    getProductName(itemDetails) {
      return itemDetails?.name;
    },
    getStateName(stateId) {
      return states[stateId];
    },
    onReturned() {
      this.showReturnedDialog = true;
    },
    async fetchNextStates(stateId) {
      const nextStates = await supplyService.getNextStatusId(stateId);
      // orderDialog("nextStates", nextStates);
      const nextStateIds = nextStates.map(state => state.id_state_next);
      if (nextStateIds.length) {
        // orderDialog("this.states", this.states);
        this.filteredStates = this.states.map(
          // eslint-disable-next-line array-callback-return,consistent-return
          state => {
            if (nextStateIds.includes(state.id.toString())) {
              return state;
            }
            if (this.supply.id_state === state.id.toString()) {
              return state;
            }
          }
        );
        this.filteredStates = this.filteredStates.filter(item => item);
        // orderDialog("this.filteredStates", this.filteredStates);
      }
    },
    async declineSupply() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("uuid", this.supply.uuid);
        formData.append("id_state", 322);
        const response = await supplyService.updateSupplyItem(formData);
        await this.fetchNextStates(322);
        this.loading = false;
        EventBus.$emit("supply-in-modified",response);
      } catch (e) {
        console.log(e)
        this.loading = false;
      }
    },
    async confirmSupply() {
      try {
        this.confirmLoading = true;
        const formData = new FormData();
        formData.append("uuid", this.supply.uuid);
        formData.append("id_state", 321);
        await supplyService.updateSupplyItem(formData);
        await this.fetchNextStates(321);
        this.confirmLoading = false;
        EventBus.$emit("supply-in-modified");
        EventBus.$emit("ns-code-updated");
        this.visibility = false;
        this.setSuccessNotification(this.$t("supply.delivery_successfully_confirmed"));
      } catch (e) {
        console.log(e)
        this.confirmLoading = false;
      }
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        try {
          this.loading = true;
          const formData = new FormData();
          // orderDialog("this.supply", this.supply);
          formData.append("uuid", this.supply.uuid);
          if (!this.permissions.client) {
            // eslint-disable-next-line camelcase
            const uuid_sender_address = this.clients.filter(
              item => item.uuid === this.supplyItem.uuid_user
            );
            formData.append("uuid_sender_address", uuid_sender_address[0].uuid_address);
          }
          if (this.permissions.owner) {
            formData.append("uuid_deport", this.getUser.uuid_deport);
          }

          const changedData = this.$getChangedData(this.supplyItem, this.inputProducts);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });

          const response = await supplyService.updateSupplyItem(formData);
          this.setSuccessNotification(this.$t("supply.delivery_successfully_update"));
          this.loading = false;
          EventBus.$emit("supply-in-modified",response.data?.object);
          EventBus.$emit("ns-code-updated");
          this.visibility = false;
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e.error.description);
        }
      }
    },
    getAddress(addressItem) {
      let result = `${addressItem.city}, вул. ${addressItem.street} ${addressItem.house_number}`;
      if (addressItem.flat_number) {
        result += `, кв. ${addressItem.flat_number}`;
      }
      return result;
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    isDisabledDetails() {
      if (this.tabStep === 0 && !this.productsInСonfirmed.length) {
        return true;
      }
      if (this.tabStep === 1 && !this.returnedItems.length) {
        return true;
      }
      if (this.tabStep === 2 && !this.productsIn.length) {
        return true;
      }
      return false;
    },
    isReturned() {
      return this.supply.uuid_parent !== defaultUUID;
    },

    clientUuidErrors() {
      const errors = [];
      if (!this.permissions.client) {
        if (!this.supplyItem.uuid_user) {
          errors.push(this.$t("form.errors.NameRequired"));
        }
      }
      return errors;
    }
  }
};
</script>

<style lang="scss">
.supplyInTab {
  &__nav {
    display: flex;
    &-item {
      background-color: transparent !important;
      box-shadow: none;
      position: relative;
      transition: all 0.3s ease;
      text-transform: none;
      border-radius: 0;
      padding: 0;
      margin-right: 32px;

      &:last-child {
        margin: 0;
      }

      &[disabled="disabled"],
      &.theme--light.v-btn--disabled {
        background-color: transparent !important;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 3px;
        bottom: -1px;
        box-shadow: none;
        background-color: var(--v-green-base);
        transition: all 0.3s ease;
      }

      &.active {
        color: var(--v-green-base);

        &:after {
          width: 100%;
        }
      }
    }
  }
  &__content {
    &-null {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      // border-top: thin solid rgba(0, 0, 0, 0.12);
      padding: 16px;
      text-align: center;
    }
  }
}
@media screen and (max-width: 960px) {
}
</style>
